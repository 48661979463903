import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// if u change breakpoints here update _breakpoints.scss for consistency
const MOBILE_BREAKPOINT = 475;  // 
const TABLET_BREAKPOINT = 1024;

export enum DeviceBreakpoints {
  MOBILE_BREAKPOINT = 475,
  TABLET_BREAKPOINT = 1024
}

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  private isMobile = new BehaviorSubject<boolean>(window.innerWidth <= MOBILE_BREAKPOINT);
  private isTablet = new BehaviorSubject<boolean>(window.innerWidth > MOBILE_BREAKPOINT && window.innerWidth <= TABLET_BREAKPOINT);
  private isDesktop = new BehaviorSubject<boolean>(window.innerWidth > TABLET_BREAKPOINT);
  private width = new BehaviorSubject<number>(window.innerWidth);

  // Observable streams
  public isMobile$ = this.isMobile.asObservable();
  public isTablet$ = this.isTablet.asObservable();
  public isDesktop$ = this.isDesktop.asObservable();
  public width$ = this.width.asObservable();


  constructor() {
    // Listen to resize events and update the BehaviorSubjects
    window.addEventListener('resize', () => {
      const innerWidth = window.innerWidth;

      this.width.next(innerWidth);
      this.isMobile.next(innerWidth <= MOBILE_BREAKPOINT);
      this.isTablet.next(innerWidth > MOBILE_BREAKPOINT && innerWidth <= TABLET_BREAKPOINT);
      this.isDesktop.next(innerWidth > TABLET_BREAKPOINT);
    });
  }
}